import {graphql} from '@apollo/client/react/hoc';
import {navigate} from 'gatsby';
import gql from 'graphql-tag.macro';
import PropTypes from 'prop-types';
import {compose, setPropTypes} from 'recompose';
import dateDiffInDays from '../helpers/dateDiffInDays';
import notification from '../helpers/notification';
import spinnerWhileLoading from './spinnerWhileLoading';

const GET_DEGREES_SUBSCRIBED = gql`
  {
    allDegrees {
      edges {
        node {
          id
          slug
          cohortSet {
            edges {
              node {
                id
                mentors {
                  id
                }
              }
            }
          }
        }
      }
    }
    me {
      id
      isSuperuser
      subscriptionSet {
        edges {
          node {
            id
            status
            cohort {
              id
              startDate
              degree {
                id
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export default compose(
  setPropTypes({
    isDegree: PropTypes.bool.isRequired,
    trackSlug: PropTypes.string.isRequired,
    authenticated: PropTypes.bool.isRequired,
  }),
  graphql(GET_DEGREES_SUBSCRIBED, {
    skip: ({isDegree, authenticated}) =>
      typeof window === 'undefined' || !isDegree || !authenticated,

    props: ({data, ownProps}) => {
      const {error, loading, me, allDegrees} = data;
      const {trackSlug} = ownProps;
      if (loading)
        return {
          loading,
        };
      if (error) {
        navigate('/degrees');
        return {error};
      }
      if (!me) {
        notification({id: 'you_cant_access_to_this_page'});
        navigate('/degrees');
        return null;
      }
      const {
        subscriptionSet: {edges},
        isSuperuser,
        id,
      } = me;
      const isMentor =
        allDegrees &&
        allDegrees.edges.some(
          ({node: {slug, cohortSet}}) =>
            slug === trackSlug &&
            cohortSet &&
            cohortSet.edges &&
            cohortSet.edges.some(
              ({node: {mentors}}) =>
                mentors &&
                mentors.some((mentor) => (mentor && mentor.id) === id),
            ),
        );
      const today = new Date();
      const degreeAccess =
        isSuperuser ||
        isMentor ||
        (edges &&
          edges.some(
            ({
              node: {
                status,
                cohort: {degree, startDate},
              },
            }) =>
              degree.slug === trackSlug &&
              dateDiffInDays(today, startDate) <= 0 &&
              status === 'PAID',
          ));
      if (!degreeAccess) {
        notification({id: 'you_cant_access_to_this_page'});
        navigate('/degrees');
        return {loading: true};
      }

      return null;
    },
  }),

  spinnerWhileLoading(({loading}) => loading),
);
