import {Match} from '@reach/router';
import DynamicCourseDetailsContainer from 'modules/courses/containers/DynamicCourseDetailsContainer';
import React from 'react';
import NotFoundPage from 'theme/pages/NotFound';

const DegreeCourseDetailsPage = () => {
  return (
    <Match path="/degrees/:trackSlug/:courseSlug">
      {({match}) =>
        match ? (
          <DynamicCourseDetailsContainer {...match} isDegree />
        ) : (
          <NotFoundPage />
        )
      }
    </Match>
  );
};

export default DegreeCourseDetailsPage;
