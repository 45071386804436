import {graphql} from '@apollo/client/react/hoc';
import redirectIfNotSubscribed from 'core/libs/hoc/redirectIfNotSubscribed';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import log from 'core/libs/logger';
import COURSE from 'modules/courses/graphql/course.graphql';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {branch, compose, setPropTypes} from 'recompose';
import NotFoundPage from 'theme/pages/NotFound';
import CourseDetailsContainer from './CourseDetailsContainer';

const DynamicCourseDetailsContainer = (props) => {
  return <CourseDetailsContainer {...props} />;
};

const enhance = compose(
  connect(({auth}) => {
    return {
      authenticated: auth.authenticated,
    };
  }),
  setPropTypes({
    courseSlug: PropTypes.string.isRequired,
    trackSlug: PropTypes.string.isRequired,
  }),
  //redirectIfNotSubscribed,
  graphql(COURSE, {
    alias: 'withCourse',
    options: ({courseSlug}) => ({
      variables: {
        slug: courseSlug,
      },
    }),
    props: ({data, ownProps: {courseSlug}}) => {
      const {error, courses, loading, refetch} = data;
      if (loading) return {loading};
      if (error) return {error};
      if (courses && courses.edges && !courses.edges[0]) {
        log("Didn't find a course for this slug", {
          courseSlug,
          currentUrl: typeof window !== 'undefined' && window.location.href,
        });
        return {error: "Didn't find a course for this slug"};
      }

      const refetchCourse = () =>
        refetch({
          variables: {
            slug: courseSlug,
          },
        });

      const course = courses.edges[0].node;
      return {
        loading: false,
        course,
        refetchCourse,
      };
    },
  }),
  spinnerWhileLoading(({loading}) => loading),
  branch(
    ({error}) => error,
    () => NotFoundPage,
  ),
);
export default enhance(DynamicCourseDetailsContainer);
